import React from 'react'

export default function LinedHeading2({ heading, className }) {
    return (
        <div className={'flex justify-center items-center py-11 text-white w-[calc(100vw-5rem)] overflow-hidden m-auto rounded-sm ' + className}>
            <span className='bg-gradient-to-l w-full h-[1.5px] from-transparent to-white'></span>
            <h2 className='px-4 serif text-xl whitespace-nowrap '>{heading}</h2>

            <span className='bg-gradient-to-r w-full h-[1.5px] from-transparent to-white'></span>
        </div>

    )
}
