import React from 'react'
import NavBar from '../components/NavBar'
import LinedHeading from '../ui/LinedHeading'
import CenteredPara from '../ui/CenteredPara'
import Footer from '../components/Footer'

export default function ProcessAndFabrics() {
    return (
        <main className='bg-accent max-md:bg-darkaccent w-full text-white'>
            <NavBar pageTitle={"PROCESS & FABRICS"} />
            <div className='h-10 max-lg:hidden'></div>
            <CenteredPara className={'font-light text-[20px] max-lg:hidden max-md:max-w-lg '} content="OUR BESPOKE PROCESS IS DESIGNED TO ENSURE EVERY GARMENT MEETS THE HIGHEST STANDARDS OF CRAFTSMANSHIP, FROM CONSULTATION TO COMPLETION" />

            <div className='max-lg:hidden'><Process /></div>
            <div className="lg:hidden">
                <ProcessInPhone />
            </div>
            <div className='max-lg:hidden py-[8rem]'><Fabrics /></div>
            <div className="lg:hidden"><FabricsInPhone /></div>
            <Footer />

        </main>
    )
}

function Process() {
    return (
        <section>
            <LinedHeading heading={'OUR PROCESS'} />

            <div className="gap-y-32 grid grid-cols-[1fr_auto_1fr] PROCESS">
                <div className='text-right mt-auto ml-auto'>
                    <ProcessAndDescription heading={"Personal Consultation"}
                        description={"Begin with a tailored consultation, guided by our expert tailors to select from a range of premium fabric"} />
                </div>
                <div className='row-span-2 p-20'>
                    <img className='w-[25vw] pointer-events-none select-none' src="/process/iconsmix.png" alt="" />
                </div>
                <div className='mt-auto' >
                    <ProcessAndDescription heading={"Design & Customization"}
                        description="Collaborate with our stylists to design every detail, from cut to finishing touches" />
                </div>
                <div className='text-right ml-auto'>
                    <ProcessAndDescription heading={"Master Craftsmanship"}
                        description="Your garment is expertly crafted, blending traditional methods with refined contemporary techniques." />
                </div>
                <div>
                    <ProcessAndDescription heading={"Precision Fitting"}
                        description="Return for a fitting where any adjustments are made, ensuring the perfect fit and finish" />
                </div>
            </div>


        </section>
    )
}

function ProcessInPhone() {
    return (
        <div className='mt-[1rem]'>
            <LinedHeading className={'mb-[2rem]'} heading={'OUR PROCESS'} />
            <ProcessAndDescription iconUrl="/process/consultation.png" heading={"Personal Consultation"}
                description={"Begin with a tailored consultation, guided by our expert tailors to select from a range of premium fabric"} />
            <ProcessAndDescription  iconUrl="/process/design.png" heading={"Design & Customization"}
                description="Collaborate with our stylists to design every detail, from cut to finishing touches" />
            <ProcessAndDescription iconUrl="/process/master.png" heading={"Master Craftsmanship"}
                description="Your garment is expertly crafted, blending traditional methods with refined contemporary techniques." />
            <ProcessAndDescription iconUrl="/process/fitting.png" heading={"Precision Fitting"}
                description="Return for a fitting where any adjustments are made, ensuring the perfect fit and finish" />        </div>
    )
}

function FabricsInPhone() {
    return (
        <section className='w-full'>
            <div className='justify-center items-center grid grid-flow-row w-full'>
                <img className='mr-auto ml-0' src="/process/top.png" alt="" />
            <LinedHeading heading={'OUR FABRICS'} />
                <div className=''>
                    <p className='m-auto pb-[5rem] max-w-[25rem] font-light text-center md:leading-[3rem] max-md:font-light  max-md:text-[15px] max-md:leading-[2] max-md:px-[1rem]'>
                        Discover our selection of luxury fabrics from the world’s top mills, including Zegna,
                        Drago, Guabello, Reda, Loro Piana, and more.
                    </p>
                </div>
                <div className=' items-center gap-10 m-auto pb-7 flex space-x-1 animate-loop-scroll max-md:mb-[1rem]'>
                    <img className='h-[3rem]' src="/process/1.png" alt="" />
                    <img className='h-[1.5rem]' src="/process/2.png" alt="" />
                    <img className='h-[1.5rem]' src="/process/3.png" alt="" />
                    <img className='h-[1.5rem]' src="/process/4.png" alt="" />
                    <img className='h-[2rem]' src="/process/5.png" alt="" />
                </div>
            </div>
        </section>
    )
}


function ProcessAndDescription({ heading, description, iconUrl }) {
    return (
        <div className='max-xl:m-auto max-xl:pb-[5rem] max-lg:text-center'>
            {iconUrl ? <img className='max-xl:m-auto pb-[2rem] max-xl:pb-[1.5rem] h-[120px] max-xl:h-[80px]' src={iconUrl} alt="" /> : ""}
            <h2 className='pb-3 uppercase text-[18px] font-medium  max-md:text-[15px] max-md:leading-[2] max-md:pb-0'>{heading}</h2>
            <p className='max-xl:m-auto max-w-sm max-xl:max-w-lg text-[19px] font-light md:font-extralight max-md:max-w-sm max-md:font-extralight max-md:px-8  max-md:text-[15px] max-md:leading-1 max-md:pb-0'>{description}</p>
        </div>
    )
}

function Fabrics() {
    return (
        <section className='w-full'>
            <LinedHeading heading={'OUR FABRICS'} />
            <div className='justify-center items-center grid grid-cols-[2fr_1fr_1fr] w-full'>
                <div className=''>
                    <p className='pl-20 max-w-[510px] font-light leading-[3rem] '>
                        Discover our selection of luxury fabrics from the world’s top mills, including Zegna,
                        Drago, Guabello, Reda, Loro Piana, and more.
                    </p>
                </div>
                <div className='flex flex-col items-center gap-10'>
                    <div><img className='h-[4rem]' src="/process/1.png" alt="" /></div>
                    <div><img className='h-[2rem]' src="/process/2.png" alt="" /></div>
                    <div><img className='h-[2rem]' src="/process/3.png" alt="" /></div>
                    <div><img className='h-[2rem]' src="/process/4.png" alt="" /></div>
                    <div><img className='h-[2rem]' src="/process/5.png" alt="" /></div>
                </div>
                <img className='mr-auto ml-0' src="/process/side.png" alt="" />
            </div>
        </section>
    )
}


