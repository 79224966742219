import React from "react";
import NavBar from "../components/NavBar";
import LinedHeading from "../ui/LinedHeading";
import CenteredPara from "../ui/CenteredPara";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";

export const serviceGroups = [
  {
    heading: "BESPOKE TAILORING",
    link: "/respoketailoring",
    services: [
      {
        imageUrl: "/services/suits.jpg",
        title: "Suits & Tuxedos",
        description:
          "Impeccably crafted suits and tuxedos \n that exude sophistication and \n timeless elegance",
      },
      {
        imageUrl: "/services/shirt.jpg",
        title: "Shirts & Trousers",
        description:
          "Custom shirts and trousers, expertly \n tailored to capture the essence of refined \n style and perfect fit",
      },
      {
        imageUrl: "/services/jacket.jpg",
        title: "Jackets",
        description:
          "Bespoke jackets that seamlessly \n blend heritage craftsmanship with \n modern aesthetics",
      },
    ],
  },
  {
    heading: "ALTERATIONS & STYLING",
    link: "/alterationandstyling",
    services: [
      {
        imageUrl: "/services/restyling.png",
        title: "Alterations & Restyling",
        description:
          "Masterful alterations and restyling services to \n enhance the fit and refresh the look of your \n cherished garments",
      },
      {
        imageUrl: "/services/consultation.png",
        title: "Personal Consultations",
        description:
          "Personalized styling sessions offering expert \n guidance to define your signature style \n and create a distinctive wardrobe.",
      },
    ],
  },
  {
    heading: "FOOTWEAR & ACCESSORIES",
    link: "/footwearandaccessories",
    services: [
      {
        imageUrl: "/services/shoes.png",
        title: "Handmade Shoes",
        description:
          "Masterful alterations and restyling services to \n enhance the fit and refresh the look of your \n cherished garments",
      },
      {
        imageUrl: "/services/accessories.png",
        title: "Accessories",
        description:
          "Personalized styling sessions offering expert \n guidance to define your signature style \n and create a distinctive wardrobe.",
      },
    ],
  },
];
export default function Services() {
  return (
    <main className="bg-accent max-md:bg-darkaccent text-white ">
      <NavBar pageTitle={"SERVICES"} />
      <LinedHeading className={"max-md:hidden  "} heading={"SERVICES"} />
      <CenteredPara
        className={"text-[20px] font-light  md:max-w-[1250px] py-8 text-white px-1 pt-1 max-md:max-w-full  max-md:text-[14px]  max-md:pt-10 max-md:pb-10 max-md:leading-[1.75]"}
        content="Discover the full range of services we offer at Santoba Tailors, each designed to enhance your style and fit seamlessly into your lifestyle."
      />
      <div className="h-10 max-md:hidden"></div>
      {serviceGroups.map((item, index) => {
        return (
          <ServiceGroup
            key={item.link + index.toString()}
            heading={item.heading}
            link={item.link}
            serviceItems={item.services}
          />
        );
      })}
      <div className="h-[5rem]"></div>
      <Footer />
    </main>
  );
}

function ServiceGroup({ heading, link, serviceItems }) {
  const navigate = useNavigate();
  return (
    <section className={`m-auto w-fit`}>
      <div className="h-[1.5rem]"></div>
      <h2 className="text-center text-[33px] serif max-md:text-[26px]">{heading}</h2>

      <div className="h-[6rem] max-md:h-[2rem]"></div>
      <div className="gap-[4rem] grid grid-flow-col max-md:grid-flow-row ">
        {serviceItems.map((item, index) => {
          return (
            <div
              key={item.title + index.toString()}
              className="gap-3 grid w-full max-md:mb-1 pb-2"
              onClick={() => {
                // navigate(link)
              }}
            >
              <img id='image' 
                className=" max-md:max-h-[304px] h-[290px]  object-cover object-center aspect-square w-full max-h-[355px]"
                src={item.imageUrl}
                alt=""
              />
              <h3 className="text-center uppercase font-medium text-[15.82px] px-2 max-md:text-[14px]">
                {item.title}
              </h3>
              <p className="w-full max-w-[400px] text-center text-[13.39px] uppercase max-md:m-auto font-light md:whitespace-pre-line max-md:text-[12px] max-md:max-w-[calc(100vw-2rem)] px-1">
                {item.description}
              </p>
            </div>
          );
        })}
      </div>
      <div className="h-20"></div>
    </section>
  );
}
