import React, { useState } from "react";
import NavBar from "../components/NavBar";
import LinedHeading from "../ui/LinedHeading";
import Button from "../ui/Button";
import Footer from "../components/Footer";
import SearchIcon from "@mui/icons-material/Search";

export default function Faq() {
  const faqList = [
    {
      question: "Where are you located?",
      answer:
        "We are located at Meena Bazaar - Showroom #10 & 11, Al Majid Property - Bur Dubai - Al Souq Al Kabeer - Dubai.",
    },
    {
      question: "What are your timings?",
      answer: [
        {
          title: "Saturday - Thursday",
          value: "10:00 am - 2:00 pm, 4:30 pm - 10:00 pm",
        },
        {
          title: "Friday",
          value: "4:30 pm - 10:00 pm",
        },
      ],
      height: "100",
    },
    {
      question: "What services do you offer?",
      answer:
        "We offer bespoke tailoring services, dressing you from head to toe with custom clothing. Our offerings include suits, shirts, trousers, and casual wear. Additionally, we specialize in accessories such as shoes, ties, cufflinks, and pocket squares.",
    },
    {
      question: "How long does it take to create a bespoke garment?",
      answer:
        "The process typically takes 2 weeks, depending on the complexity of the design and availability of materials. Rush services may be available upon request.",
    },
    {
      question: "What types of fabrics do you offer?",
      answer:
        "We provide a wide range of fabrics, including Wool, Linen, Cotton, Denim, and Silk, catering to every occasion and season.",
    },
    {
      question: "Which fabric brands do you offer?",
      answer:
        "Our premium fabric collection includes world-renowned brands such as Loro Piana, Ermenegildo Zegna, Holland & Sherry, Reda, Drago, Guabello, and more.",
    },
    {
      question: "Is bespoke tailoring only for formal wear?",
      answer:
        "No, we also specialize in bespoke casual wear, including linen shirts, pants, chinos, shorts, and coordinated sets, ensuring you look stylish for every occasion.",
    },
    {
      question: "How many fittings are required to make a suit?",
      answer: [
        {
          title: "Trial Fitting",
          value:
            "To assess measurements and fit, with adjustments made as needed.",
        },
        {
          title: "Final Fitting",
          value:
            "To ensure the suit is perfect. Most clients collect their suit after this stage.",
        },
      ],
      height: "170",
    },
    {
      question: "What is the turnover time for shirts, suits, and pants?",
      answer: [
        {
          title: "Shirt",
          value: "3 days",
        },
        {
          title: "Trousers",
          value: "7 days",
        },
        {
          title: "Suit",
          value: "10-14 days",
        },
      ],
      height: "90",
    },
    {
      question: "Do you offer express services?",
      answer:
        "Yes, we provide express services for an additional charge, with a turnaround time of 5-7 days.",
      height: "83",
    },
    {
      question: "Do you offer home/office fittings?",
      answer:
        "Yes, we can visit your home or office at your convenience within our shop timings.",
    },
    {
      question: "Do you tailor for women?",
      answer:
        "Yes, we have tailors and consultants who specialize in bespoke clothing for women.",
    },
    {
      question: "How do I schedule an appointment?",
      answer:
        "You can schedule an appointment by contacting us via WhatsApp, calling us directly, or registering on our website with your contact details. We will get in touch to arrange your visit.",
    },
    {
      question: "Do you ship internationally?",
      answer:
        "Yes, we ship to all countries accessible by DHL. Shipping costs will be borne by the customer.",
    },
    {
      question: "Do you provide alteration services?",
      answer:
        "Yes, we offer alteration services exclusively for garments made by Santoba Tailors.",
    },
    {
      question: "What payment methods do you accept?",
      answer:
        "We accept cash and card payments. Additionally, we accept American dollars, euros, and sterling pounds.",
    },
    {
      question: "Do you offer discounts and promotions?",
      answer:
        "Yes, we run seasonal promotions and offers on selected fabrics. Sign up for our newsletter to stay updated on the latest deals.",
    },
    {
      question: "Can you replicate a design from a photo?",
      answer: "We can use photos as inspiration to create a unique garment.",
      height: "66",
    },
    {
      question: "What customizations do you offer?",
      answer: [
        {
          title: "Customization Options",
          value:
            "Over 100 lining choices, lapel styles, button selections, embroidery, and personalized initials on shirts and jackets.",
        },
      ],
      height: "100",
    },
    {
      question:
        "What is the difference between bespoke and made-to-measure tailoring?",
      answer: [
        {
          title: "Bespoke Tailoring",
          value:
            "Creating a garment entirely from scratch based on your unique measurements and preferences, offering complete customization.",
        },
        {
          title: "Made-to-Measure Tailoring",
          value:
            "Adjusting pre-existing patterns to fit your measurements but with limited customization.",
        },
      ],
      height: "210",
    },
    {
      question:
        "What happens if I lose or gain weight after the garment is made?",
      answer:
        "We provide alteration services to ensure your garments continue to fit you perfectly, even if your measurements change over time.",
    },
    {
      question: "Do you provide corporate tailoring services?",
      answer:
        "Yes, we offer bespoke solutions for businesses, including custom-tailored uniforms and corporate attire. Contact us to learn more about our corporate packages.",
    },
    {
      question: "Do you make wedding attire?",
      answer:
        "Absolutely! We specialize in wedding attire for brides, grooms, and wedding parties. Explore our wedding packages for a truly bespoke experience.",
    },
    {
      question: "What if I’m unsure about my style preferences?",
      answer:
        "Our expert consultants are here to guide you. During your appointment, we’ll recommend styles, fabrics, and designs that complement your personality and suit the occasion.",
    },
    {
      question: "Do you offer group fittings or packages?",
      answer:
        "Yes, we provide group fittings for weddings, corporate events, and other special occasions. Contact us to discuss group packages tailored to your needs.",
    },
    {
      question: "How do I care for my bespoke garments?",
      answer:
        "We provide care instructions specific to your garment. For suits, we recommend professional dry cleaning and proper storage in breathable garment bags to maintain their shape and longevity.",
    },
  ];
  const limit = 8;
  const [activeFaqIndex, setActiveFaqIndex] = useState(0);
  const [searchText, setSearchText] = useState("");

  return (
    <main className="bg-accent max-md:bg-darkaccent _h-screen text-white ">
      <NavBar />
      <h2 className="m-auto font-light text-[18.69px] text-center mt-[5rem]">
        YOUR QUERIES
      </h2>
      <div className="border-[.5px]  border-white grid grid-cols-[auto_2rem]  m-auto py-1 md:min-w-[200px] max-w-[calc(100vw-3rem)] md:max-w-sm my-5 ">
        <input
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          className="bg-transparent border-none outline-none px-3 "
          type="text"
          placeholder="START SEARCHING"
        />
        <SearchIcon />
      </div>
      <div className="grid md:grid-cols-2 m-auto md:px-10 pt-[8rem] max-md:pt-[4rem] container pb-[3rem]">
        <div className="LEFT px-10">
          <h1 className="font-light text-[60px] max-md:text-[24px]">FAQ</h1>
        </div>
        <div className="flex flex-col pb-[3rem] transition-all duration-1000 RIGHT">
          {faqList.map((faqItem, index) => {
            if (searchText === "")
              // return index < limit ? (
              return true ? (
                <FaqItem
                key={index} 
                  index={index}
                  faqItem={faqItem}
                  activeFaqIndex={activeFaqIndex}
                  setActiveFaqIndex={setActiveFaqIndex}
                />
              ) : (
                <></>
              );
            else if (
              (searchText !== "" &&
                faqItem.answer.includes(searchText.toLowerCase())) ||
              faqItem.question.toLowerCase().includes(searchText.toLowerCase())
            )
              return (
                <FaqItem
                  index={index}
                  faqItem={faqItem}
                  activeFaqIndex={activeFaqIndex}
                  setActiveFaqIndex={setActiveFaqIndex}
                />
              );
            else return <></>;
          })}
        </div>
      </div>
      <Footer />
    </main>
  );
}

function FaqItem({ index, faqItem, activeFaqIndex, setActiveFaqIndex }) {
  return (
    <div
      className={`grid lg:grid-cols-[20px_500px] md:grid-cols-[20px_300px] max-md:grid-cols-[20px_calc(100vw-4rem)]  bg-transparent hover:bg-gradient-to-r from-[#00000010] transition-all px-5 mt-[1rem] pt-[1rem] w-full cursor-pointer ${
        activeFaqIndex === index ? "" : ""
      }`}
      onClick={() => {
        setActiveFaqIndex(index);
      }}
    >
      <div className="md:mr-2 font-extrabold text-[10.32px]">
        {index < 9 ? 0 : ""}
        {index + 1}.
      </div>
      <div className=" ">
        <div className="max-w-[500px] w-full md:w-[500px] max-md:w-[calc(100vw-4rem)]  text-[18px] uppercase max-md:text-[14px] font-normal">
          {faqItem.question}
        </div>
        <div
          className={` transition-all duration-1000 overflow-hidden font-extralight pt-2 ${
            activeFaqIndex === index ? "pb-5 " : "h-0 "
          }`}
          style={{
            height: `${
              activeFaqIndex === index
                ? `${ faqItem.height ||  faqItem.answer.length / 1.5}px`
                : "0"
            }`,
          }}
        >
          {Array.isArray(faqItem.answer) ? (
            <div>
              {faqItem.answer.map((answerItem, index) => {
                return (
                  <li  key={index} >
                    <b>{answerItem.title}: </b> {answerItem.value}{" "}
                  </li>
                );
              })}
            </div>
          ) : (
            <div className="max-w-[500px] w-full md:w-[500px] max-md:w-[calc(100vw-4rem)]   font-extralight max-md:text-[14px]">
              {faqItem.answer.toString()}
            </div>
          )}
        </div>
        <img className={`mt-[-10px]`} src="/ui/faqline.svg" alt="" />
      </div>
    </div>
  );
}

function Input({ placeholder }) {
  return (
    <>
      <input
        className="border-gray-500 bg-accent max-lg:m-auto border-b max-w-[20rem] text-white max-lg:text-center outline-none"
        placeholder={placeholder}
        type="text"
      />
      <br />{" "}
    </>
  );
}
