import React from 'react'

export default function LinedHeading({ heading, className }) {
    return (
        <div className={'flex justify-center items-center bg-accent max-md:bg-darkaccent py-11 w-full text-white ' + className}>
            <span>
                <img src="/ui/headingline.png" alt="" />
            </span>
            <h2 className='px-4 serif text-[33px] whitespace-nowrap max-lg:text-2xl te'>{heading}</h2>
            <span>
                <img src="/ui/headinglineright.png" alt="" />
            </span>
        </div>
    )
}
