import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import LinedHeading from "../ui/LinedHeading";
import Footer from "../components/Footer";
import Button from "../ui/Button";
import { useNavigate } from "react-router-dom";
import { blogsData } from "../lib/blogs.data";
import { getAllBlogs, getAllBlogsInShort, getPublishedBlogsInShort } from "../lib/firebase";
import { ImageChangableContainer } from "./BlogArticle";

export default function Blog() {
  const [blogsInShort, setBlogsInShort] = useState([]);
  useEffect(() => {
    getPublishedBlogsInShort().then((result) => {
      setBlogsInShort(result);
    });
    return () => {};
  }, []);
  const navigate = useNavigate();
  return (
    <main className="bg-accent max-md:bg-darkaccent">
      <NavBar pageTitle={"BLOG"} />
      <LinedHeading className={"max-md:hidden"} heading={"BLOG"} />
      <div className="gap-3 grid m-auto md:px-10 pb-[4rem] max-w-[90rem] container min-h-[75vh]">
        {blogsInShort?.map((item, index) => {
          return (
            <div>
              <BlogItem
                link={item?.link || `/blog/${item?._id}`}
                key={item?.heading + index.toString()}
                isImageLeft={index % 2 == 0}
                imageUrl={item?.coverImage}
                heading={item?.heading}
                content={item?.content} 
              />
              {blogsInShort?.[index + 1] ? (
                <div className="_max-md:hidden bg-gray-600 m-auto md:my-[2rem] max-md:mt-[2rem] h-[2px] max-w-[1100px] w-[calc(100%-2.5rem)]">
                  {" "}
                </div>
              ) : (
                ""
              )}
            </div>
          );
        })}
      </div>
      <div
        className="m-auto mb-[2rem] p-5 font-light text-[19px text-center text-white cursor-pointer"
        onClick={() => {
          // navigate('#')
        }}
      >
        {/* READ MORE */}
      </div>
      <Footer />
    </main>
  );
}

export function BlogItem({
  isImageLeft,
  imageUrl,
  heading,
  content,
  link,
  containerClassName,
  isAdmin,
  imageClassName
}) {
  const navigate = useNavigate();
  return (
    <div
      className={
        "max-md:block gap-7 grid grid-cols-2 m-auto md:pr-5 max-w-[1100px] text-white max-md:text-center  max-md:m-auto max-md:w-full max-md:px-5 " +
        containerClassName
      }
    >
      <h2 className="md:hidden max-md:mt-[2rem] mb-8 max-md:font-thin max-md:text-[20px] heading leading-tight" dangerouslySetInnerHTML={{ __html: heading }}></h2>
      <div
        className={`overflow-hidden RIGHT  max-md:m-auto w-full ${
          isImageLeft ? "order-2" : ""
        }`}
      >
        <ImageChangableContainer
          containerClassName={""}
          image={imageUrl || 'https://placehold.co/500x550/0F1219/FFF'}
          imgClassName={
            "w-full max-md:h-full object-center object-cover max-md:max-h-[290px] h-[350px] "+imageClassName
          }
          isAdmin={isAdmin}
        />
      </div>
      <div
        className={`md:pl-5 LEFT grid  ${
          isImageLeft ? "order-1" : ""
        }`}
      >
        <h2 className="max-md:hidden mb-8 text-[15.61px] heading leading-[40px]" dangerouslySetInnerHTML={{ __html: heading }}></h2>
        <p className="max-md:m-auto max-md:mt-[1.75rem] max-w-[35rem] font-light text-[14.93px] text-sm leading-loose  max-md:text-[15px] max-md:leading-[2] max-md:pb-0 "  dangerouslySetInnerHTML={{ __html: `${content?.substring(0, 170)}...` }}>
          
        </p>
        <Button
          onClick={() => {
            navigate(link, { viewTransition: true });
          }}
          className={
            "mt-[2rem] max-md:mt-[1rem] w-min  whitespace-nowrap uppercase max-md:mx-auto h-fit"
          }
        >
          Read more
        </Button>
      </div>
    </div>
  );
}
