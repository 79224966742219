import React from "react";
import NavBar from "../components/NavBar";
import LinedHeading from "../ui/LinedHeading";
import CenteredPara from "../ui/CenteredPara";
import Button from "../ui/Button";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";

export default function Wedding() {
  const navigate = useNavigate()
  return (
    <>
      <main className="bg-accent max-md:bg-darkaccent pb-[3rem] text-center text-white">
        <NavBar pageTitle={"WEDDING"} />
        <LinedHeading className={"max-md:hidden"} heading={"WEDDING"} />
        <div className="h-[3px]"></div>
        <CenteredPara
          className={"text-[19.64px] font-light max-w-[1400px] xl:whitespace-pre-line text-white max-md:max-w-xl px-2 max-md:px-[10px] pt-1  max-md:text-[13.5px] max-md:pt-5 max-md:pb-10 max-md:leading-[1.75] "}
          content={`For your special day, only perfection will do. Santoba Tailors offers bespoke wedding attire that exudes elegance,\n sophistication, and a personal touch. From the groom to the wedding party, we craft timeless pieces ensuring\n everyone looks their best.`}
        />
        <div className="h-[3px]"></div>
        <Images />
        {/* <CenteredPara className={'text-[20.64px] font-light'}  content="For your special day, only perfection will do. Santoba Tailors offers bespoke wedding attire that exudes elegance, sophistication, and a personal touch. From the groom to the wedding party, we craft timeless pieces ensuring everyone looks their best." /> */}
        <div className="h-[3px]"></div>
        <Button className={"m-auto mb-[2rem]"} onClick={()=> {
          navigate('/contactus')
        }}>
          BOOK FOR YOUR SPECIAL DAY
        </Button>
        <div className="h-[10px]"></div>
        {/* <Images /> */}
        {/* <Button className={'mb-10'}>VIEW MORE</Button> */}
      </main>
      <Footer />
    </>
  );
}


function Images() {
  return (
    <div className="md:gap-3 grid grid-cols-3 m-auto px-5 md:py-10 max-md:pb-8 container max-md:grid-cols-1  overflow-hidden mb-[2rem]">
      <img id='image' className="h-[300px] w-full object-cover" src="/wedding/1.jpg" alt="" />
      <img id='image' className="h-[300px] w-full object-cover" src="/wedding/2.jpg" alt="" />
      <img id='image' className="h-[300px] w-full object-cover" src="/wedding/3.jpg" alt="" />
    </div>
  );
}
