import firebase from './firebase'
import { getAllBlogs } from "./firebase";

export const blogsData = [
  {
    id: "blog-1",
    _id: "blog-1",
    isPublished: false,
    heading: "The Ultimate Guide for Groom & Groomsmen",
    link: "the-ultimate-guide-for-groom-groomsmen-blog-1",
    images: [
      "/blog/1.png"
    ],
    article: [
      {
        type: "intro",
        heading: "",
        content: `When most people think of wedding fashion, the bride and bridesmaids often steal the spotlight. But let’s not forget the groom and his groomsmen—this is their moment to shine too.\nA wedding is the perfect occasion to showcase your best attire and leave a lasting impression. Whether you’re a fan of classic styles or prefer to stand out with a bold statement, we’ve got you covered with options to suit every taste.`,
      },
      {
        type: "left-imaged-para",
        heading: "Timeless Classics",
        content: `
          <ul>
              <li><font class="font-bold">The Classic Black Tux:</font> Exuding elegance and tradition, a black tux is ideal for formal or black-tie weddings. Pair it with a crisp white shirt, black bow tie, and patent leather shoes for a polished look.</li>
              <li><font class="font-bold">Beige Wedding Suit:</font> Perfect for outdoor or beach weddings, a beige suit offers a relaxed yet refined vibe. Linen fabric enhances comfort, especially in warmer weather.</li>
              <li><font class="font-bold">White Wedding Tuxedo:</font> For a bold yet sophisticated look, a slim-fit white tuxedo jacket with black accents creates a sharp, timeless ensemble.</li>
          </ul>`,
      },
      {
        type: "right-imaged-para",
        heading: "Unique Themes and Styles",
        content: `For themed weddings, incorporate elements that reflect the event’s mood. For rustic settings, opt for earthy tones or textured fabrics. For a beach wedding, lightweight materials like linen in light shades keep things breezy and elegant.`,
      },
      {
        type: "para-with-only-heading",
        heading: "Groomsmen’s Style: Creating Harmony",
        content: `
          <ul>
              <li>Navy Suit, White Shirt, Black Shoes</li>
              <li>Beige Jacket, White Shirt, Black Bow Tie</li>
              <li>Light Gray Tuxedos, Baby Blue Ties, Brown Oxfords</li>
              <li>Brown Suits with Floral Ties</li>
          </ul>`,
      },
      {
        type: "para-with-only-heading",
        heading: "When Should You Start Shopping?",
        content: `Begin your search four months before the big day to avoid last-minute stress. Consult with our expert stylists at Santoba Tailors to ensure your wedding attire aligns with your vision. Schedule your appointment today!`,
      },
    ],
  },
  {
    id: "blog-2",
    _id: "blog-2",
    isPublished: true,
    heading: "Why Dressing Like a Professional Still Matters",
    link: "why-dressing-like-a-professional-still-matters-blog-2",
    images: [
      "/blog/2.png"
    ],
    article: [
      {
        type: "intro",
        heading: "Why Dressing Like a Professional Still Matters",
        content: `Your attire speaks volumes before you say a word. Regardless of your office's dress code, dressing professionally remains a vital aspect of workplace success.`,
      },
      {
        type: "left-imaged-para",
        heading: "Why Is Professional Dressing Important?",
        content: `
          <ul>
              <li><font class="font-bold">First Impressions Matter:</font> Your outfit reflects your seriousness and commitment to your role.</li>
              <li><font class="font-bold">Boosts Confidence:</font> Wearing well-fitted clothing can enhance self-assurance.</li>
              <li><font class="font-bold">Increases Credibility:</font> Professional attire conveys competence and reliability.</li>
          </ul>`,
      },
      {
        type: "right-imaged-para",
        heading: "Must-Have Executive Wardrobe Essentials",
        content: `
          <p><font class="font-bold">For Men:</font></p>
          <ul>
              <li>Well-fitted Suits in neutral colors like navy or gray</li>
              <li>Crisp White and Light Blue Shirts</li>
              <li>Tailored Trousers and Dress Shoes</li>
              <li>Accessories like cufflinks, ties, and a leather bag</li>
          </ul>
          <p><font class="font-bold">For Women:</font></p>
          <ul>
              <li>Tailored Blazers and Dress Pants</li>
              <li>Pencil Skirts and Professional Dresses</li>
              <li>Minimal Accessories like a watch or a scarf</li>
          </ul>`,
      },
    ],
  },
  {
    id: "blog-3",
    _id: "blog-3",
    isPublished: true,
    heading: "Beat the Heat: A Guide to Breathable Workwear in Dubai Summers",
    link: "beat-the-heat-a-guide-to-breathable-workwear-in-dubai-summers-blog-3",
    images: [
      "/blog/1.png"
    ],
    article: [
      {
        type: "intro",
        heading:
          "Beat the Heat: A Guide to Breathable Workwear in Dubai Summers",
        content: `Dubai summers can be relentless, but looking professional doesn’t have to mean sacrificing comfort. Here's how to stay cool and polished in the heat.`,
      },
      {
        type: "left-imaged-para",
        heading: "Top Tips for Hot Weather Dressing",
        content: `
          <ul>
              <li>Choose Light Colors: White, beige, and pastels reflect sunlight, keeping you cooler.</li>
              <li>Opt for Breathable Fabrics: Linen, cotton, and lightweight wool are ideal choices.</li>
              <li>Prioritize Comfort: Loose-fitting clothes and ventilated designs allow for airflow.</li>
          </ul>`,
      },
      {
        type: "right-imaged-para",
        heading: "Essential Summer Wardrobe Staples",
        content: `
          <ul>
              <li>Linen Shirts and Trousers</li>
              <li>Light-colored Blazers</li>
              <li>Comfortable Loafers or Moccasins</li>
          </ul>`,
      },
    ],
  },
  {
    id: "blog-4",
    _id: "blog-4",
    isPublished: true,
    heading: "The Ultimate Guide to Fabric Selection",
    link: "the-ultimate-guide-to-fabric-selection-blog-4",
    images: [
      "/blog/2.png"
    ],
    article: [
      {
        type: "intro",
        heading: "The Ultimate Guide to Fabric Selection",
        content: `The fabric you choose for your suit is just as important as the design. At Santoba Tailors, we source from the finest mills to ensure you look and feel your best.`,
      },
      {
        type: "left-imaged-para",
        heading: "Understanding Wool Count (Super Number)",
        content: `
          <ul>
              <li>Super 80s - 100s: Durable and perfect for daily wear.</li>
              <li>Super 120s - 150s: Softer, lighter, and ideal for special occasions.</li>
              <li>Super 160s and Beyond: Luxurious but delicate, reserved for high-end suits.</li>
          </ul>`,
      },
      {
        type: "right-imaged-para",
        heading: "Top Fabric Brands We Offer",
        link: "how-to-transition-your-wardrobe-from-work-to-weekend-blog-5",
        content: `
          <ul>
              <li>Ermenegildo Zegna: Known for fine wool and cashmere blends.</li>
              <li>Loro Piana: Synonymous with luxury, offering soft, lightweight fabrics.</li>
              <li>Drago: Perfect for tailored suits with superior comfort.</li>
              <li>Reda: Elegant Merino wool fabrics for timeless looks.</li>
          </ul>`,
      },
    ],
  },
  {
    id: "blog-5",
    _id: "blog-5",
    isPublished: true,
    heading: "How to Transition Your Wardrobe from Work to Weekend",
    link: "how-to-transition-your-wardrobe-from-work-to-weekend-blog-5",
    images: [
      "/blog/7.png"
    ],
    article: [
      {
        type: "intro",
        heading: "How to Transition Your Wardrobe from Work to Weekend",
        content: `Modern professionals often juggle demanding schedules, leaving little time for wardrobe changes between work and social engagements. The solution? A versatile wardrobe that transitions effortlessly from the office to weekend plans.`,
      },
      {
        type: "left-imaged-para",
        heading: "Key Pieces for a Seamless Transition",
        content: `
          <ul>
              <li>Blazers: A tailored blazer works just as well over a crisp shirt at work as it does over a casual tee for a dinner outing.</li>
              <li>Chinos: Swap out tailored trousers for chinos. They strike the perfect balance between professional and relaxed.</li>
              <li>Button-Down Shirts: Keep a collection of button-downs in neutral colors—they pair well with both suits and jeans.</li>
              <li>Shoes: Invest in versatile loafers or leather sneakers that work for both formal and casual settings.</li>
          </ul>`,
      },
    ],
  },
  {
    id: "blog-6",
    _id: "blog-6",
    isPublished: true,
    heading: "The Art of Tailoring: Why Fit Is Everything",
    link: "the-art-of-tailoring-why-fit-is-everything-blog-6",
    images: [
      "/blog/6.png"
    ],
    article: [
      {
        type: "intro",
        heading: "The Art of Tailoring: Why Fit Is Everything",
        content: `When it comes to clothing, fit is king. Even the most expensive outfit can fall flat if it doesn’t fit well, while a perfectly tailored garment can elevate your style instantly.`,
      },
      {
        type: "left-imaged-para",
        heading: "Why Does Fit Matter?",
        content: `
          <ul>
              <li>Confidence Boost: Clothes that fit perfectly enhance your silhouette and boost your confidence.</li>
              <li>Comfort: Tailored pieces conform to your body, ensuring all-day comfort without compromise.</li>
              <li>Style Statement: A well-fitted garment always looks polished and intentional, no matter the occasion.</li>
          </ul>`,
      },
      {
        type: "right-imaged-para",
        heading: "Common Fit Issues Tailoring Can Solve",
        content: `
          <ul>
            <li>Jackets with ill-tting shoulders or sleeves</li>
            <li> Shirts that are too tight around the chest or waist</li>
            <li> Dresses or skirts with incorrect lengths</li>
            <li>Pants that are too long or short</li>
            <li>Comfort: Tailored pieces conform to your body, ensuring all-day comfort without compromise.</li>
            <li>Style Statement: A well-fitted garment always looks polished and intentional, no matter the occasion.</li>
          </ul>`,
      },
      {
        type: "para-with-only-heading",
        heading: "Common Fit Issues Tailoring Can Solve",
        content: `
          <ul>
          <li>Pants that are too long or short</li>
            <li>Jackets with ill-fitting shoulders or sleeves</li>
            <li>Shirts that are too tight around the chest or waist</li>
            <li>Dresses or skirts with incorrect lengths</li>
          </ul>`,
      },
      {
        type: "para-with-only-heading",
        heading: "Why Choose Bespoke Tailoring?",
        content: `
          With bespoke tailoring, every detail is customized for you, from the cut and fabric to the buttons
            and lining. At Santoba Tailors, we pride ourselves on delivering garments that not only t perfectly
            but also reect your unique style. \n
            Book a consultation with us and experience the transformative power of a tailored wardrobe.`,
      },
    ],
  },
  {
    id: "blog-7",
    _id: "blog-7",
    isPublished: true,
    heading: "Wedding Style Beyond the Suit: Accessories That Elevate",
    link: "wedding-style-beyond-the-suit-accessories-that-elevate-blog-7",
    images: [
      "/blog/5.png"
    ],
    article: [
      {
        type: "intro",
        heading: "Wedding Style Beyond the Suit: Accessories That Elevate",
        content: `A well-tailored suit is essential for wedding attire, but the right accessories can take your look to the next level. From the groom to the groomsmen, accessories add personality and flair to any outfit.`,
      },
      {
        type: "left-imaged-para",
        heading: "Essential Wedding Accessories",
        content: `
        <ul>
            <li><font class="font-bold">Pocket Squares:</font> A classic pocket square adds a pop of color or pattern to your suit. Choose one that complements your tie or boutonniere.</li>
            <li><font class="font-bold">Cufflinks:</font> Elegant cufflinks can add sophistication and personality to your shirt cuffs.</li>
            <li><font class="font-bold">Ties and Bowties:</font> Whether you go for a bold pattern or a classic color, your tie or bowtie sets the tone for your look.</li>
            <li><font class="font-bold">Shoes:</font> Opt for polished Oxfords or loafers that match the color of your suit.</li>
            <li><font class="font-bold">Boutonnieres:</font> A small floral touch can tie your look to the wedding theme.</li>
        </ul>`,
      },
      {
        type: "right-imaged-para",
        heading: "Pro Styling Tips",
        content: `
        <ul>
            <li>Keep it Balanced: Avoid over-accessorizing—choose 2-3 statement pieces.</li>
            <li>Match Metals: Ensure your belt buckle, watch, and cufflinks are in the same metal tone.</li>
            <li>Coordinate Colors: Make sure accessories complement your suit and the wedding theme.</li>
        </ul>`,
      },
    ],
  },
];

