import React from "react";

export default function Map({className}) {
  return (
      <iframe
        className={"grayscale w-full " + className}
        title="Santoba Tailors"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.276414209573!2d55.29549449999999!3d25.2612854!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43da2d72edbd%3A0x993cd5be64bb8383!2sSantoba%20Tailors!5e0!3m2!1sen!2sin!4v1732979731604!5m2!1sen!2sin"

      ></iframe>
  );
}
