import React from 'react'
import NavBar from './NavBar'
import LinedHeading from '../ui/LinedHeading'
import CenteredPara from '../ui/CenteredPara'
import Footer from './Footer'
import { serviceGroups } from '../pages/Services'


export default function SingleService({ heading, introduction, serviceIndex }) {
    return (
        <main className='bg-accent max-md:bg-darkaccent text-white'>
            <NavBar pageTitle={heading} />
            <LinedHeading className={"max-md:hidden"} heading={heading} />
            <CenteredPara className={introduction ? "mt-[3rem]" : ""} content={introduction} />
            <div className={introduction ? "h-10" : ""}></div>
            <ServiceGroup heading={""} serviceItems={serviceGroups[serviceIndex].services} />
            <Footer />
        </main>
    )
}

function ServiceGroup({ heading, serviceItems }) {
    return (
        <section className={`m-auto w-fit`}>
            <h2 className='text-center text-xl serif'>{heading}</h2>
            <div className='h-10'></div>
            <div className="gap-4 grid grid-flow-col max-md:grid-flow-row ">
                {
                    serviceItems.map((item, index) => {
                        return <div key={item.title + index.toString()} className='gap-3 grid w-full max-md:mb-10  pb-2 px-5'>
                            <h3 className='text-center md:hidden'>{item.title}</h3>
                            <img id='image' className='max-md:w-[calc(100vw-4rem)] max-md:max-h-[19rem] object-cover object-center aspect-square' src={item.imageUrl} alt="" />
                            <h3 className='text-center max-md:hidden'>{item.title}</h3>
                            <p className='w-full max-md:max-w-[35rem] max-w-sm text-center text-sm uppercase max-md:m-auto'>{item.description}</p>
                        </div>
                    })
                }
            </div>
            <div className='h-20'></div>
        </section>
    )
}

