import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { testimonials } from "../pages/home";
import "swiper/css";
import { useRef } from "react";
import SwiperCore from "swiper";
import { Autoplay } from "swiper/modules";
export default function Swipable({ data = testimonials }) {
  const swiperRef = useRef(null);

  SwiperCore.use([Autoplay]);

  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      speed={1200}
      loop={true}
      autoplay={{
        delay: 4000,
        pauseOnMouseEnter: true,
        waitForTransition: true,
      }}
      onSwiper={(swiper) => (swiperRef.current = swiper)}
      // onSlideChange={() => console.log("slide change")}
      // onSwiper={(swiper) => console.log(swiper)}
      className="w-full"
    >
      {data.map((item, index) => {
        return (
          <SwiperSlide className="" key={item.author + index.toString()}>
            <p className="m-auto max-w-[55rem] text-center italic font-extralight  max-md:text-[15.78px] ">
              {item.content}
            </p>
            <p className="pt-5 text-center font-extralight text-[15.61px] max-md:text-[13.78px] max-md:mb-7">
              - {item.author.toUpperCase()}
            </p>
          </SwiperSlide>
        );
      })}
      <div className="flex items-center pb-[3rem] pt-[2.5rem] max-md:pt-[2px]">
        <div className="flex justify-between m-auto w-[5rem]">
          <button
            onClick={() => {
              swiperRef.current.slidePrev();
            }}
          >
            <img src="ui/prev.png" alt="" />
          </button>
          <button
            onClick={() => {
              swiperRef.current.slideNext();
            }}
          >
            <img src="ui/next.png" alt="" />
          </button>
        </div>
      </div>
    </Swiper>
  );
}
