import { toast } from "react-toastify";

export default function themedAlert(text, color: "red" | "green" | "blue") {
  if ((color === "green" || !color))
    toast(text, {
      theme: "light",
      position: "bottom-right",
      style: { backgroundColor: "#d4ffd4", color: "black" },
      progressStyle: { height: "1px", backgroundColor: "black" },
    });
  else if ((color === "red"))
    toast(text, {
      theme: "light",
      position: "bottom-right",
      style: { backgroundColor: "#ffd4d4", color: "black" },
      progressStyle: { height: "1px", backgroundColor: "black" },
    });
  else if ((color === "blue"))
    toast(text, {
      theme: "light",
      position: "bottom-right",
      style: { backgroundColor: "#d4e7ff", color: "black" },
      progressStyle: { height: "1px", backgroundColor: "black" },
    });
}
