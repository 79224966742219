import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  orderBy,
  query,
  doc,
  deleteDoc,
  updateDoc,
  getDoc,
  setDoc,
  serverTimestamp,
  where,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  listAll,
  ref,
  uploadBytes,
} from "firebase/storage";
import themedAlert from "./alert";
import { blogsData } from "./blogs.data";

const firebaseConfig = {
  // PRODUCTION ORIGINAL
  apiKey: "AIzaSyDDYzqB7_Z8xg9Hca97IHKQD3MeNdqdKT0",
  authDomain: "santoba-website.firebaseapp.com",
  projectId: "santoba-website",
  storageBucket: "santoba-website.firebasestorage.app",
  messagingSenderId: "798127451913",
  appId: "1:798127451913:web:e66d2a2983f0d98517edd8",
  measurementId: "G-P3S129LSV8",
  storageLink: "gs://santoba-website.firebasestorage.app",

  // FROM ashraf14823@gmail.com
  // US
  // apiKey: "AIzaSyBZsa14hFOFO0SzJLpPaHaNbz-iN8jgTp0",
  // authDomain: "santora-tailoring-us.firebaseapp.com",
  // projectId: "santora-tailoring-us",
  // storageBucket: "santora-tailoring-us.firebasestorage.app",
  // messagingSenderId: "613184824572",
  // appId: "1:613184824572:web:ded5f65aa87f1eb6fee379",
  // measurementId: "G-EN68BP268B",
  // storageLink: "gs://santora-tailoring-us.firebasestorage.app"

  // DAMAM
  // apiKey: "AIzaSyA4ZTAavBsjEfyW3IHPFaku7wslRaCmTs4",
  // authDomain: "santora-tailoring.firebaseapp.com",
  // projectId: "santora-tailoring",
  // storageBucket: "santora-tailoring.firebasestorage.app",
  // messagingSenderId: "65114002693",
  // appId: "1:65114002693:web:ba1d5a81313f5c8b04136c",
  // measurementId: "G-SRE9MPXRWB"
  // storageLink: "gs://santora-tailoring.firebasestorage.app"
};

const app = initializeApp(firebaseConfig);
console.log("Firebase initialized: ", app);

export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app, firebaseConfig.storageLink);

export async function getLookbook() {
  const lookbookCol = collection(db, "lookbook");
  const lookbookQuery = query(lookbookCol, orderBy("createdAt", "desc"));
  const lookbookSnapshot = await getDocs(lookbookQuery);
  const lookbookList = lookbookSnapshot.docs.map((doc) => ({
    id: doc.id, // Include document ID if needed
    ...doc.data(), // Spread document data
  }));
  console.log({ lookbookList });
  return lookbookList;
}

export async function addLookbook(newLookbook) {
  try {
    newLookbook = { ...newLookbook, createdAt: Date.now().toString() };
    const lookbookCol = collection(db, "lookbook");
    const docRef = await addDoc(lookbookCol, newLookbook);
    console.log("Document written with ID: ", docRef.id);
    themedAlert("Saved successfully. ");
    return docRef.id;
  } catch (e) {
    console.error("Error adding document: ", e);
    themedAlert("Error while adding to database " + e?.message);
    throw e;
  }
}

export async function deleteLookbook(docId, imagePath) {
  try {
    const docRef = doc(db, "lookbook", docId);
    await deleteDoc(docRef);
    deleteFile(imagePath);
    console.log(`Document with ID: ${docId} has been deleted successfully.`);
    themedAlert("Deleted successfully.");
  } catch (e) {
    console.error("Error deleting document: ", e);
    themedAlert("Error while deleting from database " + e?.message);
    throw e;
  }
}

export const uploadFile = async (file, folder) => {
  const storageRef = ref(storage, folder + Date.now().toString() + file.name);
  try {
    const uploadResult = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(uploadResult.ref);
    themedAlert("Uploaded file successfully");
    return downloadURL;
  } catch (error) {
    themedAlert("Error while uploading file " + error.message);
  }
};

export const deleteFile = async (filePath) => {
  try {
    const fileRef = ref(storage, filePath);
    await deleteObject(fileRef);
    console.log(`File at path "${filePath}" has been deleted successfully.`);
    themedAlert("File deleted successfully.");
  } catch (error) {
    console.error("Error deleting file: ", error);
    themedAlert("Error while deleting file " + error.message);
    throw error;
  }
};

export async function addSubscription(subscriptionEmail) {
  try {
    subscriptionEmail = {
      email: subscriptionEmail,
      createdAt: Date.now().toString(),
    };
    const subscriptionCol = collection(db, "subscriptions");
    const docRef = await addDoc(subscriptionCol, subscriptionEmail);
    console.log("Document written with ID: ", docRef.id);
    // themedAlert("Saved successfully. ")
    return docRef.id;
  } catch (e) {
    console.error("Error adding document: ", e);
    // themedAlert("Error while adding to database " + e?.message, "red")
    throw e;
  }
}

export async function getAllSubscriptions() {
  try {
    const subscriptionsCol = collection(db, "subscriptions");
    const subscriptionsQuery = query(
      subscriptionsCol,
      orderBy("createdAt", "desc")
    );
    const subscriptionsSnapshot = await getDocs(subscriptionsQuery);
    const subscriptionsList = subscriptionsSnapshot.docs.map((doc) => ({
      _id: doc.id, // Include document ID
      ...doc.data(), // Spread the rest of the document data
    }));
    console.log({ subscriptionsList });
    return subscriptionsList;
  } catch (error) {
    console.error("Error fetching subscriptions: ", error);
    throw error;
  }
}

export async function addEnquiry(enquiry) {
  try {
    enquiry = { ...enquiry, createdAt: Date.now().toString() };
    const enquiryCol = collection(db, "enquiries");
    const docRef = await addDoc(enquiryCol, enquiry);
    console.log("Document written with ID: ", docRef.id);
    // themedAlert("Saved successfully. ")
    return docRef.id;
  } catch (e) {
    console.error("Error adding document: ", e);
    // themedAlert("Error while adding to database " + e?.message, "red")
    throw e;
  }
}

export async function getAllEnquiries() {
  try {
    const enquiriesCol = collection(db, "enquiries");
    const enquiriesQuery = query(enquiriesCol, orderBy("createdAt", "desc"));
    const enquiriesSnapshot = await getDocs(enquiriesQuery);
    const enquiriesList = enquiriesSnapshot.docs.map((doc) => ({
      _id: doc.id, // Include document ID
      ...doc.data(), // Spread the rest of the document data
    }));
    console.log({ enquiriesList });
    return enquiriesList;
  } catch (error) {
    console.error("Error fetching enquiries: ", error);
    throw error;
  }
}

export async function getAllBlogs() {
  // return blogsData

  const blogCol = collection(db, "blogs");
  const blogQuery = query(blogCol, orderBy("createdAt", "desc")); // Order by descending creation date
  const blogSnapshot = await getDocs(blogQuery);
  const blogList = blogSnapshot.docs.map((doc, index) => ({
    ...doc.data(), // Spread the rest of the document data
    _id: doc.id, // Include document ID
    id: `Blog ${blogSnapshot.size - index}`,
  }));
  console.log({ blogList });
  return blogList;
}

export async function getBlogByDocId(docId) {
  // return blogsData.find(blog => blog.id=== docId)
  try {
    const blogDoc = doc(db, "blogs", docId);
    const docSnapshot = await getDoc(blogDoc);

    if (docSnapshot.exists()) {
      const blogData = { _id: docSnapshot.id, ...docSnapshot.data() };
      console.log({ blogData });
      return blogData;
    } else {
      console.log("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching document: ", error);
    throw error;
  }
}

// New function to save the blog in the "blogs-in-short" collection
async function saveBlogsInShort(blogId, newBlog) {
  try {
    const blogShortRef = doc(db, "blogs-in-short", blogId); // Custom ID matches the blog ID
    const shortBlog = {
      _id: blogId,
      heading: newBlog.heading,
      content: newBlog.article?.[0]?.content || "", // Safely access the first content
      coverImage: newBlog.coverImage,
      createdAt: newBlog.createdAt || serverTimestamp(), // Use existing timestamp or generate new
      isPublished: newBlog.isPublished || false,
    };
    await setDoc(blogShortRef, shortBlog); // Write to Firestore
    console.log(`Short blog saved with ID: ${blogId}`);
  } catch (e) {
    console.error("Error saving short blog: ", e);
    throw e; // Propagate the error
  }
}

export async function getAllBlogsInShort() {
  // return blogsData
  try {
    const blogsInShortCol = collection(db, "blogs-in-short");
    const blogsInShortQuery = query(
      blogsInShortCol,
      orderBy("createdAt", "desc")
    );
    const blogsInShortSnapshot = await getDocs(blogsInShortQuery);
    const blogsInShortList = blogsInShortSnapshot.docs.map((doc, index) => ({
      _id: doc.id, // Include document ID
      id: blogsInShortSnapshot.size - index,
      ...doc.data(), // Spread document data
    }));
    console.log({ blogsInShortList });
    return blogsInShortList;
  } catch (error) {
    console.error("Error fetching blogs in short: ", error);
    throw error;
  }
}

export async function getPublishedBlogsInShort() {
  // return blogsData
  try {
    const blogsInShortCol = collection(db, "blogs-in-short");
    const blogsInShortQuery = query(
      blogsInShortCol,
      orderBy("createdAt", "desc"),
      where("isPublished", "==", true)
    );
    const blogsInShortSnapshot = await getDocs(blogsInShortQuery);
    const blogsInShortList = blogsInShortSnapshot.docs.map((doc, index) => ({
      _id: doc.id, // Include document ID
      id: blogsInShortSnapshot.size - index,
      ...doc.data(), // Spread document data
    }));
    console.log({ blogsInShortList });
    return blogsInShortList;
  } catch (error) {
    console.error("Error fetching blogs in short: ", error);
    throw error;
  }
}

// Updated addBlog function
export async function addBlog(newBlog) {
  try {
    const blogId = `${Date.now()}`;
    const blogRef = doc(db, "blogs", blogId);

    newBlog = {
      ...newBlog,
      link: `/blog/${blogId}`,
      createdAt: serverTimestamp(),
    };

    await setDoc(blogRef, newBlog);
    console.log("Document written with ID: ", blogId);

    // Save in blogs-in-short collection
    await saveBlogsInShort(blogId, newBlog);

    themedAlert("Saved successfully.");
    return blogId;
  } catch (e) {
    console.error("Error adding document: ", e);
    themedAlert("Error while adding to database: " + e?.message);
    throw e;
  }
}

// Updated editBlog function
export async function editBlog(blogId, updatedBlog) {
  try {
    const blogDoc = doc(db, "blogs", blogId);
    updatedBlog = {
      ...updatedBlog,
      link: `/blog/${blogId}`,
      updatedAt: Date.now().toString(),
    };
    await updateDoc(blogDoc, updatedBlog);
    console.log(`Blog with ID: ${blogId} updated successfully.`);

    // Update in blogs-in-short collection
    await saveBlogsInShort(blogId, updatedBlog);

    themedAlert("Blog updated successfully.");
  } catch (error) {
    console.error("Error updating blog: ", error);
    themedAlert("Error while updating the blog: " + error.message, "red");
    throw error;
  }
}

// Updated deleteBlog function
export async function deleteBlog(blogId) {
  try {
    const blogDoc = doc(db, "blogs", blogId);
    await deleteDoc(blogDoc);
    console.log("Document successfully deleted with ID: ", blogId);

    // Delete from blogs-in-short collection
    const blogShortDoc = doc(db, "blogs-in-short", blogId);
    await deleteDoc(blogShortDoc);
    console.log("Short blog successfully deleted with ID: ", blogId);

    themedAlert("Deleted successfully.");
    return true;
  } catch (e) {
    console.error("Error deleting document: ", e);
    themedAlert("Error while deleting: " + e?.message);
    throw e;
  }
}

export async function getBlogImages() {
  const blogImagesCol = collection(db, "blog-images");
  const blogImagesQuery = query(blogImagesCol, orderBy("createdAt", "desc"));
  const blogImagesSnapshot = await getDocs(blogImagesQuery);
  const blogImagesList = blogImagesSnapshot.docs.map((doc) => ({
    id: doc.id, // Include document ID if needed
    ...doc.data(), // Spread document data
  }));
  console.log({ blogImagesList });
  return blogImagesList;
}

export async function addBlogImage(newBlogImage) {
  try {
    newBlogImage = { url: newBlogImage, createdAt: Date.now().toString() };
    const blogImagesCol = collection(db, "blog-images");
    const docRef = await addDoc(blogImagesCol, newBlogImage);
    console.log("Document written with ID: ", docRef.id);
    return docRef.id;
  } catch (e) {
    console.error("Error adding document: ", e);
    throw e;
  }
}
