import React, { useEffect, useState } from "react";
import AdminLayout from "./AdminLayout";
import BlogsSideBar from "./BlogsSideBar";
import { ParaWithImage } from "../BlogArticle";
import { blogsData } from "../../lib/blogs.data";
import { useSearchParams } from "react-router-dom";
import { ButtonGroup, Button, List } from "@mui/material";
import {
  Add,
  FormatAlignCenter,
  FormatAlignLeft,
  FormatClear,
  ListAltRounded,
  ListOutlined,
  PlusOne,
} from "@mui/icons-material";
import applyTextStyle from "../../lib/applyTextStyle";
import BlogEditor from "./BlogEditor";

export default function BlogManager() {
  return (
    <AdminLayout>
      <BlogsSideBar  />
      <section className="bg-accent min-h-full text-white ">
        <BlogEditor />
      </section>
    </AdminLayout>
  );
}
