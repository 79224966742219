import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import AuthStateMonitor from "../../lib/GoogleAuthStateMonitor";
import { logOut } from "../../lib/auth";
import LockIcon from "@mui/icons-material/Lock";
import CollectionsIcon from "@mui/icons-material/Collections";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ImagePreviewer from "../../ui/image-preview/ImagePreviewer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../../ui/Button";
import { ContactMail, Email, Message } from "@mui/icons-material";

export default function AdminLayout({ children }) {
  return (
    <>
      <ToastContainer />
      <AuthStateMonitor>
        <ImagePreviewer />
        <div
          className={`grid md:grid-cols-[15rem_auto] h-[100dvh] max-md:grid-rows-[auto_min-content] `}
        >
          <AdminNavDesktop className={"max-md:hidden"} />
          <div className="overflow-auto">{children}</div>
          <AdminNavPhone className={"md:hidden"} />
        </div>
      </AuthStateMonitor>
    </>
  );
}
const menuItem = [
  {
    display: "Blog",
    link: "/admin/blog",
    icon: <AssignmentIcon />,
  },
  {
    display: "Lookbook",
    link: "/admin/lookbook",
    icon: <CollectionsIcon />,
  },
  {
    display: "Enquiries",
    link: "/admin/enquiries",
    icon: <Email />,
  },
  {
    display: "Subscriptions",
    link: "/admin/subscriptions",
    icon: <ContactMail />,
  },
];
function AdminNavDesktop({ className }) {
  const navigate = useNavigate();
  return (
    <>
      <div
        className={
          "bg-accent w-full h-full text-gray-100 p-5 transition-all grid grid-rows-[2rem_auto_2rem] grid-cols-1 border-r border-gray-300 " +
          className
        }
      >
        <div></div>

        <ul className="MENU">
          {menuItem.map((item, index) => {
            return (
              <li
                key={item.link}
                onClick={() => {
                  navigate(item.link);
                }}
                className={`hover:bg-gray-900 mb-2 p-2 hover:text-white cursor-pointer ${
                  window.location.pathname.includes(item.link) || window.location.pathname === item.link  ? "bg-gray-900" : ""
                }`}
              >
                {" "}
                {item.display}{" "}
              </li>
            );
          })}
        </ul>
        <Button
          className=""
          onClick={() => {
            logOut();
          }}
        >
          Logout
        </Button>
      </div>
    </>
  );
}
function AdminNavPhone({ className }) {
  const navigate = useNavigate();
  return (
    <div
      className={
        "bg-[#0f1825] w-full h-full text-gray-100 p-1 transition-all grid grid-rows-[2rem_auto_2rem] max-md:grid-rows-[0_auto] grid-cols-1 max-md:h-[5rem] " +
        className
      }
    >
      <div></div>
      <ul className="max-md:grid _max-md:grid-cols-3 grid-flow-col MENU">
        {menuItem.map((item, index) => {
          return (
            <li
              key={item.link}
              onClick={() => {
                navigate(item.link);
              }}
              className={`mb-2 p-2 hover:text-white cursor-pointer grid items-center justify-center text-center ${
                window.location.pathname.includes(item.link) || window.location.pathname === item.link  ? "bg-gray-700" : ""
              }`}
            >
              <div> {item.icon}</div>
              <div className="text-[10px]"> {item.display}</div>
            </li>
          );
        })}
        <li
          className={`mb-2 p-2   hover:text-white cursor-pointer grid items-center justify-center text-center `}
          onClick={() => {
            logOut();
          }}
        >
          <div>
            {" "}
            <LockIcon />
          </div>
          <div className="text-[10px]"> Logout</div>
        </li>
      </ul>
    </div>
  );
}
