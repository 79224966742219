import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import LinedHeading from "../ui/LinedHeading";
import Footer from "../components/Footer";
import { blogsData } from "../lib/blogs.data";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import "medium-editor/dist/css/medium-editor.css";
import "medium-editor/dist/css/themes/default.css";
import Button from "../ui/Button";
import {
  addBlogImage,
  addLookbook,
  getBlogByDocId,
  getBlogImages,
  getLookbook,
  getPublishedBlogsInShort,
  uploadFile,
} from "../lib/firebase";

export default function BlogArticle() {
  const [blog, setBlog] = useState([]);
  const [blogsInShort, setBlogsInShort] = useState([]);
  const { blogId } = useParams();
  useEffect(() => {
    getPublishedBlogsInShort().then((result) => {
      setBlogsInShort(result);
    });
    return () => {};
  }, []);
  useEffect(() => {
    const docIdFromLink =
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ];
    console.log({ docIdFromLink });
    getBlogByDocId(blogId).then((result) => {
      setBlog(result);
    });
    return () => {};
  }, [blogId]);

  return (
    <main className="bg-accent max-md:bg-darkaccent text-white">
      <NavBar pageTitle={"LOOK BOOK"} />
      <LinedHeading className={"max-md:hidden"} heading={"BLOG"} />
      <BlogContent
        heading={blog?.heading}
        intro={blog?.article?.[0]?.content}
        twoimages={[blog?.images?.[0], blog?.images?.[0]]}
        imageRight={blog?.images?.[0]}
        imageLeft={blog?.images?.[0]}
        article={blog?.article}
        threeArticleImagesAndDescription={[
          {
            image: blogsInShort?.[0]?.coverImage,
            heading: blogsInShort?.[0]?.heading,
            description: blogsInShort?.[0]?.content,
            link: `/blog/${blogsInShort?.[0]?._id}`,
          },
          {
            image: blogsInShort?.[1]?.coverImage,
            heading: blogsInShort?.[1]?.heading,
            description: blogsInShort?.[1]?.content,
            link: `/blog/${blogsInShort?.[1]?._id}`,
          },
          {
            image: blogsInShort?.[2]?.coverImage,
            heading: blogsInShort?.[2]?.heading,
            description: blogsInShort?.[2]?.content,
            link: `/blog/${blogsInShort?.[2]?._id}`,
          },
        ]}
      />

      <Footer />
    </main>
  );
}

function BlogContent({
  heading,
  intro,
  twoimages,
  imageLeft,
  imageRight,
  article,
  threeArticleImagesAndDescription,
}) {
  const navigate = useNavigate();

  return (
    <>
      <p className="_hidden font-bold CLASS REGISTRY"></p>

      <div className="m-auto px-[2rem] max-md:px-[1.5rem] pb-[7rem] max-w-[1100px] font-light text-[15px] max-md:text-[16px] leading-loose">
        {/* main heading */}
        <h1 className="mb-10 text-center leading-tight heading">{heading}</h1>
        {/* para1 */}
        <p
          className="m-auto max-md:mt-[4rem] md:pt-10 font-light text-center resetp"
          dangerouslySetInnerHTML={{ __html: intro }}
        ></p>
        {/* <TwoImages twoimages={twoimages} /> */}
        {/* para2 */}

        {article?.map((articleSection, index) => {
          if (articleSection.type === "intro") return <div key={index}></div>;
          else if (articleSection.type === "left-imaged-para")
            return (
              <div
                key={index}
                data-container-type="left-imaged-para"
                className={`article-container-${index}`}
              >
                <ParaWithImage
                  image={articleSection.image}
                  imagePosition={"left-imaged-para"}
                  heading={articleSection.heading}
                  para={articleSection.content}
                  headingClassName={`article-para-${index}-heading`}
                  paragraphClassName={`article-para-${index}-content`}
                />
              </div>
            );
          else if (articleSection.type === "right-imaged-para")
            return (
              <div
                key={index}
                data-container-type="right-imaged-para"
                className={`article-container-${index}`}
              >
                <ParaWithImage
                  image={articleSection.image}
                  imagePosition={"right-imaged-para"}
                  heading={articleSection.heading}
                  para={articleSection.content}
                  headingClassName={`article-para-${index}-heading`}
                  paragraphClassName={`article-para-${index}-content`}
                />
              </div>
            );
          else if (articleSection.type === "one-image")
            return (
              <div
                key={index}
                data-container-type="one-image"
                className={`article-container-${index}`}
              >
                <OneImage
                  image={articleSection.imageOne}
                  imgOneClassName={`article-para-${index}-image-one`}
                />
              </div>
            );
          else if (articleSection.type === "two-images")
            return (
              <div
                key={index}
                data-container-type="two-images"
                className={`article-container-${index}`}
              >
                <TwoImages
                  twoimages={[articleSection.imageOne, articleSection.imageTwo]}
                  imgOneClassName={`article-para-${index}-image-one`}
                  imgTwoClassName={`article-para-${index}-image-two`}
                />
              </div>
            );
          else
            return (
              <div
                key={index}
                data-container-type="para-with-only-heading"
                className={`article-container-${index}`}
              >
                <ParaWithOnlyHeading
                  heading={articleSection.heading}
                  para={articleSection.content}
                  headingClassName={`article-para-${index}-heading`}
                  paragraphClassName={`article-para-${index}-content`}
                />
              </div>
            );
        })}

        <div className="h-[5rem]"></div>

        <div className="gap-8 grid grid-cols-3 max-md:grid-cols-1 m-auto mt-[2.5rem] container">
          {threeArticleImagesAndDescription?.map((item, index) => {
            return (
              <div key={index} className="gap-8 grid grid-rows-[20rem_auto]">
                <img
                  data-preview="true"
                  className="w-full h-full object-center object-cover"
                  src={item.image}
                  alt=""
                />
                <div>
                  <h2 className="mb-4 font-medium text-[14px] leading-tight min-h-[40px]">
                    {item?.heading}
                  </h2>
                  <p className="font-extralight text-[14px] resetp">
                    {item.description?.substring(0, 100)}...
                  </p>
                  <p
                    className="mt-5 w-min font-extralight text-[14px] hover:text-gray-500 whitespace-nowrap transition-all cursor-pointer resetp"
                    onClick={() => {
                      navigate(`${item.link}`);
                    }}
                  >
                    Read more
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export function ParaWithImage({
  imagePosition,
  heading,
  para,
  image,
  isAdmin = false,
  headingClassName,
  paragraphClassName,
  imgClassName,
  onChange = (e) => {},
}) {
  return (
    <div
      className="justify-between items-center gap-[2rem] grid md:grid-cols-2 m-auto py-5 w-full"
      // contentEditable={isAdmin}
    >
      <ImageChangableContainer
        key={image}
        image={image || "https://placehold.co/600x600?text=Select"}
        imgClassName={`w-[500px] object-center object-cover aspect-square  ${imgClassName}`}
        containerClassName={`${
          imagePosition === "left-imaged-para" ? "" : "order-1"
        }`}
        isAdmin={isAdmin}
      />
      <div
        className={
          "max-w-[500px] " + imagePosition === "left-imaged-para"
            ? "order-1"
            : ""
        }
      >
        <h2
          id={isAdmin ? "editable" : ""}
          className={
            "m-auto mb-5 max-w-[60rem] text-[32px] max-md:text-[28px] serif leading-tight " +
            headingClassName
          }
          contentEditable={isAdmin}
          dangerouslySetInnerHTML={{ __html: heading }}
        ></h2>
        <p
          id={isAdmin ? "editable" : ""}
          contentEditable={isAdmin}
          className={
            "font-light max-md:text-[16px] bolderb  leading-loose showlist  resetp " +
            paragraphClassName
          }
          dangerouslySetInnerHTML={{ __html: para }}
        ></p>
      </div>
    </div>
  );
}

export function ParaWithOnlyHeading({
  heading,
  para,
  isAdmin = false,
  headingClassName,
  paragraphClassName,
}) {
  return (
    <div className="pt-[2rem]">
      <h2
        id={isAdmin ? "editable" : ""}
        contentEditable={isAdmin}
        dangerouslySetInnerHTML={{ __html: heading }}
        className={
          "m-auto mb-0 pb-0 text-[32px]  max-md:text-[28px] bolderb serif leading-tight " +
          headingClassName
        }
      ></h2>

      <p
        id={isAdmin ? "editable" : ""}
        className={
          "m-auto max-md:mt-[.2rem] md:py-2 font-light _text-center max-md:text-[16px] showlist  resetp " +
          paragraphClassName
        }
        contentEditable={isAdmin}
        dangerouslySetInnerHTML={{ __html: para }}
      ></p>
    </div>
  );
}

export function TwoImages({
  twoimages,
  imgOneClassName,
  imgTwoClassName,
  isAdmin,
}) {
  if (!twoimages?.[0])
    twoimages[0] = "https://placehold.co/800x600?text=Select";
  if (!twoimages?.[1])
    twoimages[1] = "https://placehold.co/800x600?text=Select";
  return (
    <div
      className={`gap-5 max-md:gap-0 grid grid-cols-2 m-auto py-10 container `}
    >
      {twoimages?.map((image, index) => {
        return (
          <ImageChangableContainer
            key={index}
            image={image}
            imgClassName={`w-full h-[400px] object-center object-cover ${
              index === 0 ? imgOneClassName : imgTwoClassName
            } `}
            containerClassName={""}
            isAdmin={isAdmin}
          />
        );
      })}
    </div>
  );
}

export function OneImage({ image, imgClassName, isAdmin }) {
  console.log("OneImage", imgClassName);
  return (
    <div className={` `}>
      <ImageChangableContainer
        image={image || "https://placehold.co/1400x600?text=Select"}
        imgClassName={"w-full " + imgClassName}
        containerClassName={"m-auto py-10 h-[500px] overflow-hidden"}
        isAdmin={isAdmin}
      />
    </div>
  );
}

export function ImageChangableContainer({
  onChange,
  image,
  imgClassName,
  containerClassName,
  isAdmin = false,
}) {
  const [isImageFileSelectorOpen, setIsImageFileSelectorOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(image);
  return (
    <>
      {isImageFileSelectorOpen && (
        <ImageFileSelector
          setImageSelectionConfirmed={setSelectedImage}
          setSelfOpen={setIsImageFileSelectorOpen}
          className={`${
            isImageFileSelectorOpen
              ? "bg-[#00000087] backdrop-blur-sm _w-full fixed w-screen top-0 left-0 h-full z-10 grid items-center overflow-hidden transition-opacity justify-center max-md:items-end max-md:grid-cols-1 opacity-100 "
              : "opacity-0 h-0 pointer-events-none hidden"
          }`}
        />
      )}
      <div className={"relative " + containerClassName}>
        {isAdmin && (
          <Button
            className={"absolute bottom-0 left-0 "}
            onClick={() => {
              setIsImageFileSelectorOpen(true);
            }}
          >
            Change image
          </Button>
        )}
        <img
          data-preview="true"
          className={`object-center object-cover ${imgClassName} `}
          src={selectedImage}
          alt=""
        />
      </div>
    </>
  );
}

function ImageFileSelector({
  onSubmit,
  className,
  isSelfOpen,
  setSelfOpen,
  setImageSelectionConfirmed,
}) {
  const [imageFile, setImageFile] = useState(null);
  const [selectedTab, setSelectedTab] = useState("lookbook");
  const [imageSelected, setImageSelected] = useState("");
  const [lookbookImages, setLookbookImages] = useState([]);
  const [blogImages, setBlogImages] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      "image-picker": "true",
    });

    setImageSelected("");
    getLookbook().then((lookbooks, index) => {
      setLookbookImages(lookbooks);
    });
    getBlogImages().then((blogImages, index) => {
      setBlogImages(blogImages);
    });

    return () => {};
  }, [isSelfOpen]);

  return (
    <div
      contentEditable={false}
      className={className}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          setSelfOpen(false);
          setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            "image-picker": "false",
          });
        }
      }}
    >
      <div
        className={
          " w-[calc(80vw)] max-md:p-5 max-md:w-full animate-zoom-up gap-3  max-md:grid-cols-1 max-lg:grid-cols-2 text-black bg-white md:mt-5 p-10 md:border border-b border-accent md:rounded-lg max-md:max-w-full "
        }
      >
        <div className="grid grid-rows-[min-content_1fr] h-[calc(100vh-20rem)]">
          <div className="gap-1 grid grid-cols-3 -p-4 border-b border-black">
            <div
              onClick={() => {
                setSelectedTab("lookbook");
              }}
              className={` transition-all text-center p-2 rounded-sm cursor-pointer ${
                selectedTab === "lookbook" ? "bg-accent text-white" : ""
              }`}
            >
              From lookbook images
            </div>
            <div
              onClick={() => {
                setSelectedTab("blog");
              }}
              className={` transition-all text-center p-2 rounded-sm cursor-pointer ${
                selectedTab === "blog" ? "bg-accent text-white" : ""
              }`}
            >
              From blog images
            </div>
            <div
              onClick={() => {
                setSelectedTab("file");
              }}
              className={` transition-all text-center p-2 rounded-sm cursor-pointer ${
                selectedTab === "file" ? "bg-accent text-white" : ""
              }`}
            >
              Upload file
            </div>
          </div>
          <div className="mt-10 w-full h-full overflow-auto">
            {selectedTab === "lookbook" && (
              <div className="gap-1 grid grid-cols-[repeat(auto-fill,150px)] w-full h-full text-black justify-center ">
                {lookbookImages?.map((image, index) => {
                  return (
                    <div>
                      <div
                        className={`bg-accent grow-0 h-[200px] w-max overflow-hidden `}
                      >
                        <img
                          key={index}
                          onClick={() => {
                            setImageSelected(image.imageUrl);
                          }}
                          className={`object-cover  h-[200px] w-[145px]  ${
                            imageSelected === image.imageUrl ? "scale-90 " : ""
                          }`}
                          src={image.imageUrl}
                          alt=""
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {selectedTab === "blog" && (
              <div className="gap-1 grid grid-cols-[repeat(auto-fill,150px)] w-full h-full text-black justify-center ">
                {blogImages?.map((image, index) => {
                  return (
                    <div>
                      <div
                        className={`bg-accent grow-0 h-[200px] w-max overflow-hidden `}
                      >
                        <img
                          key={index}
                          onClick={() => {
                            setImageSelected(image.url);
                          }}
                          className={`object-cover  h-[200px] w-[145px]  ${
                            imageSelected === image.url ? "scale-90 " : ""
                          }`}
                          src={image.url}
                          alt=""
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {selectedTab === "file" && (
              <div className="grid  h-min items-center justify-center justify-items-center gap-5 mt-10 ">
                <div>
                  <input
                    className="p-1 border border-accent"
                    type="file"
                    accept="image/png, image/jpg, image/jpeg"
                    onChange={(e) => {
                      setImageFile(e.target.files?.[0]);
                    }}
                  />
                </div>
                <img
                  className="h-full max-h-[250px]"
                  src={imageFile && URL.createObjectURL(imageFile)}
                  alt=""
                />
              </div>
            )}
          </div>
        </div>
        <div className="grid grid-cols-4">
          {lookbookImages?.map((image, index) => {
            <img
              key={index}
              className="h-[200px]"
              src={image.imageUrl}
              alt=""
            />;
          })}
        </div>
        <button
          className="bg-accent hover:bg-accent p-1 border w-full text-white mt-[3rem]"
          onClick={async () => {
            if (selectedTab === "file") {
              const imageUrl = await uploadFile(imageFile, "blog-images/");
              addBlogImage(imageUrl);
              setImageSelected(imageUrl);
              setImageSelectionConfirmed(imageUrl);
            } else {
              setImageSelectionConfirmed(imageSelected);
            }
            setSelfOpen(false);
          }}
        >
          APPLY
        </button>
      </div>
    </div>
  );
}
