import { Close } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useHref, useResolvedPath } from "react-router-dom";

export default function ImagePreviewer({ children }) {
  const pathname = useResolvedPath().pathname;
  const [imageUrl, setImageUrl] = useState(
    "https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"
  );
  const [isOpen, setIsOpen] = useState(false);
  const [imageThumbs, setImageThumbs] = useState([]);

  useEffect(() => {
    const handleClick = (e) => {
      if (e.target.dataset.preview) {
        setImageUrl(e.target.src);
        setIsOpen(true);
        const previewingImageElementsInThePage =
          document.querySelectorAll("[data-preview]");
        const imageThumbsTemp = [];
        previewingImageElementsInThePage.forEach((element) => {
          imageThumbsTemp.push(element.src);
        });
        setImageThumbs(imageThumbsTemp);
      }
    };

    document.body.addEventListener("click", handleClick);

    return () => {
      document.body.removeEventListener("click", handleClick);
    };
  }, [pathname]);
  return (
    <>
      {isOpen && (
        <div className="top-0 left-0 z-[999] fixed justify-center items-center grid w-[100dvw] h-[100dvh]">
          <div className="justify-center items-center grid m-auto w-[calc(100vw-2rem)] h-[calc(100vh-4rem)] pointer-events-none">
            <img
              loading="eager"
              className={`transition-all object-cover pointer-events-none  animate-zoom-up max-w-[calc(100vw-3rem)] max-h-[calc(100vh-5rem)]`}
              src={imageUrl}
              alt=""
            />
          </div>
          <div className="m-auto w-fit">
            <div className="_justify-center _items-center grid grid-flow-col m-auto w-max max-w-[100vw] overflow-auto SMALL-SCROLLBAR">
              {imageThumbs.map((thumbSrc, index) => (
                <div className="justify-center items-center grid bg-white w-10 h-10">
                  <img
                    loading="lazy"
                    key={index}
                    className={`w-10 h-10 transition-all cursor-pointer object-cover object-center bg-black ${
                      imageUrl === thumbSrc ? "w-8 h-8" : ""
                    }  `}
                    onClick={() => {
                      setImageUrl(thumbSrc);
                    }}
                    src={thumbSrc}
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
          <div
            className="top-0 left-0 -z-10 absolute bg-[#000000bb] w-[100dvw] h-[100dvh] CLOSER"
            onClick={() => {
              setIsOpen(false);
            }}
          ></div>
        </div>
      )}
    </>
  );
}
