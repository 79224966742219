import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import LinedHeading from "../ui/LinedHeading";
import Footer from "../components/Footer";
import { getLookbook } from "../lib/firebase";
import ImagePreviewer from "../ui/image-preview/ImagePreviewer";

export default function LookBook() {
  const dressStyles = [
    "ALL",
    "CASUAL",
    "SUMMER",
    "CORPORATE",
    "WEDDING",
    "WOMEN",
  ];
  const [lookbookImages, setLookbookImages] = useState([
    { altText: "", imageUrl: "", category: "CASUAL" },
  ]);
  const [category, setCategory] = useState("ALL");

  useEffect(() => {
    getLookbook().then((res) => {
      setLookbookImages(res);
    });
    return () => {};
  }, []);

  return (
    <main className="bg-accent max-md:bg-darkaccent text-white">
      {/* <ImagePreviewer /> */}
      <NavBar pageTitle={"LOOK BOOK"} />
      <LinedHeading className={"max-md:hidden"} heading={"LOOK BOOK"} />
      <p className="m-auto max-w-[60rem] text-center max-md:max-w-md max-md:mt-[4rem] max-md:px-5 max-md:font-light   max-md:text-[14px]  _max-md:pt-10 _max-md:pb-10 max-md:leading-[1.75]">
        Explore our curated collection of tailored suits, jackets, shirts,
        trousers, and wedding attire. Each piece is designed to reflect the
        elegance and sophistication of Santoba Tailors' bespoke experience.
      </p>
      <ul className="flex justify-center gap-1 mt-[4rem] font-light text-[16px] leading-tight">
        {dressStyles.map((item, index) => {
          return (
            <>
              <li
                onClick={() => {
                  setCategory(item);
                }}
                className={`text-[15.58px] max-md:text-[12px] max-sm:text-[11px] transition-all p-[2px] cursor-pointer ${
                  item === category
                    ? "font-medium"
                    : " hover:text-gray-400  font-light"
                }`}
                key={index.toString()}
              >
                {item}{" "}
              </li>
              <div className="text-[15.58px] max-md:text-[12px] max-sm:text-[11px] transition-all mt-[2px] p-0">
                {" "}
                {dressStyles[index + 1] ? "|" : ""}
              </div>
            </>
          );
        })}
      </ul>
      <LookBookImages categoryFilter={category} images={lookbookImages} />
      <Footer />
    </main>
  );
}

function LookBookImages({ images, categoryFilter }) {
  return (
    <section className="bg-accent mt-[2rem] ">
      <div className="gap-0 grid grid-cols-4 max-md:grid-cols-2 overflow-hidden aspect-auto min-h-[75vh] IMAGES">
        {images
          .filter(
            (fItem) =>
              fItem.category === categoryFilter ||
              categoryFilter === "" ||
              categoryFilter === "ALL"
          )
          .map((image, index) => {
            return (
              <>
                {image.imageUrl && (
                  <img
                    data-preview="true"
                    loading="lazy"
                    className="w-full aspect-[4/5] object-cover"
                    key={image.category + index.toString()}
                    src={image.imageUrl}
                    alt={image.altText}
                  />
                )}
              </>
            );
          })}
      </div>
    </section>
  );
}
